import React, { Component } from 'react';

export class EditableTextField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            value: null
        }
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    render() {
        return (
            <>
                {(!this.state.edit)
                    ? <div>{this.state.value} <i className='fas fa-edit alt ml-3' onClick={() => this.setState({ edit: true })} /></div>
                    : <div><input type='text' value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} />
                        <i className='fas fa-check ml-1' onClick={() => (this.props.handleSave(this.props.field, this.state.value)) ? this.setState({ edit: false }) : ''} />
                        <i className='fas fa-ban ml-1' onClick={() => this.setState({ edit: false })} /></div>}
            </>
        )
    }
}

export class EditableSelectField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            value: null
        }
    }

    componentWillMount() {
        this.setState({ id: this.props.id, value: this.props.value })
    }

    getById(data, name) {
        const filteredData = data.filter((item) => {
            return item.name === name;
        });
        return filteredData[0];
    }

    render() {
        return (
            <>
                {(!this.state.edit)
                    ? <div>{this.state.value.name} <i className='fas fa-edit alt ml-3' onClick={() => this.setState({ edit: true })} /></div>
                    : <div>
                        <select onChange={(e) => this.setState({ value: this.getById(this.props.itens, e.target.value) })} required>
                            <option></option>
                            {this.props.itens.map((item) => (
                                (!!item.id && <option value={item.name} key={item.id} selected={(item.name === this.state.value.name)}>{item.name}</option>)
                            ))}
                        </select>
                        <i className='fas fa-check ml-1' onClick={() => (this.props.handleSave(this.props.field, this.state.value)) ? this.setState({ edit: false }) : this.setState({ edit: false, value: this.props.value })} />
                        <i className='fas fa-ban ml-1' onClick={() => this.setState({ edit: false })} />
                    </div>}
            </>
        )
    }
}
