import React from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'
import Footer from '../Footer'
import ScrollToTop from '../ScrollToTop'
import LogoutModal from '../LogoutModal'

const PageContainer = props => (
  <div id="wrapper">
    <Sidebar />
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <Header />
        {props.children}
      </div>
      <Footer />
      <ScrollToTop />
      <LogoutModal />
    </div>
  </div>
)

export default PageContainer
