import React from "react";

const Cardbox = ({ children, title, edit, handleEdit, handleSave }) => (
  <div className="card shadow mb-4">
    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
      <h6 className="m-0 font-weight-bold text-primary">{title}</h6>
      {edit ? (
        <div>
          <button
            className="btn btn-sm btn-danger mr-1"
            onClick={() => handleEdit(false)}
          >
            <i className="fas fa-ban fa-sm text-white-50"></i>
            &nbsp;&nbsp;Cancelar
          </button>
          <button
            class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
            onClick={() => handleSave()}
          >
            <i class="fas fa-save fa-sm text-white-50"></i>&nbsp;&nbsp;Salvar
          </button>
        </div>
      ) : (
        <button
          class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          onClick={() => handleEdit(true)}
        >
          <i class="fas fa-edit fa-sm text-white-50"></i>&nbsp;&nbsp;Editar
        </button>
      )}
    </div>
    <div className="card-body">{children}</div>
  </div>
);

export default Cardbox;
