export const getFunction = (role) => {
    if (role === 'BACK_OFFICE') {
        return [
            {
                name: 'Clientes',
                link: '/search',
                icon: 'user'
            }, {
                name: 'Produtos',
                link: '/products',
                icon: 'tags'
            }
        ];
    }

    if (role === 'WORKFORCE') {
        return [
            {
                name: 'Agenda',
                link: '/schedule',
                icon: 'table'
            }
        ];
    }

    if (role.endsWith('ADMIN')) {
        return [
            {
                name: 'Clientes',
                link: '/search',
                icon: 'user'
            }, {
                name: 'Produtos',
                link: '/products',
                icon: 'tags'
            }, {
                name: 'Agenda',
                link: '/schedule',
                icon: 'table'
            }
        ];
    }
}
