import React from "react";

export default function ServiceInfo({
  info,
  edit,
  handleEdit,
  handleFieldChange,
  handleUpdate
}) {
  return (
    <div className="card shadow mb-4">
      <form onSubmit={e => handleUpdate(e)}>
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">
            Serviço solicitado
          </h6>
          {edit ? (
            <div>
              <button
                className="btn btn-sm btn-danger mr-1"
                onClick={() => handleEdit(false)}
              >
                <i className="fas fa-ban fa-sm text-white-50"></i>
                &nbsp;&nbsp;Cancelar
              </button>
              <button
                class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
                onClick={e => handleUpdate(e)}
              >
                <i class="fas fa-save fa-sm text-white-50"></i>
                &nbsp;&nbsp;Salvar
              </button>
            </div>
          ) : (
            <button
              class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              onClick={() => handleEdit(true)}
            >
              <i class="fas fa-edit fa-sm text-white-50"></i>&nbsp;&nbsp;Editar
            </button>
          )}
        </div>
        <div className="card-body">
          <div className="input-group">
            <textarea
              disabled={!edit}
              className="form-control"
              onChange={e => {
                handleFieldChange("infoContent", e.target.value);
              }}
              value={info}
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  );
}
