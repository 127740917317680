import React from 'react';
import {Link, withRouter} from 'react-router-dom';

const CardListItem = ({ name, ticketId, attendanceDate, attendanceType }) => {
    let timeFormatted = new Date(attendanceDate).toTimeString().substring(0, 5);
    return (
        <Link to={`/schedule/${ticketId}`} className="list-group-item list-group-item-action" key={ticketId}>
            <div className="d-flex w-100 justify-content-between border-left-danger pl-2">
                <h5 className="mb-1">{name}</h5>
                <div className="text-muted font-weight-bold"><i className="fas fa-clock  mr-2"></i>{timeFormatted}</div>
            </div>
            <div className="d-flex w-100 justify-content-between border-left-danger pl-2">
                <small className="text-muted font-weight-bold">{attendanceType}</small>
            </div>
        </Link>
    )
};

export default withRouter(CardListItem);
