import React from "react";

export default function MessageHistory({
  contactList,
  edit,
  handleUpdate,
  handleEdit,
  handleFieldChange
}) {
  return (
    <div className="col-xl-8 col-lg-8">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Histórico</h6>
          <button
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            onClick={() => handleEdit(true)}
          >
            <i className="fas fa-plus fa-sm text-white-50"></i>
            &nbsp;&nbsp;Mensagem
          </button>
        </div>
        <div className="card-body">
          {edit && (
            <form onSubmit={e => handleUpdate(e)}>
              <div className="row mb-2">
                <div className="col-2">
                  <select
                    className="custom-select mr-sm-2"
                    onChange={e => handleFieldChange("type", e.target.value)}
                    required
                  >
                    <option></option>
                    <option value="E-mail">E-mail</option>
                    <option value="Telefone">Telefone</option>
                    <option value="Loja">Loja</option>
                    <option value="Obra">Obra</option>
                    <option value="WhatsApp">Whatsapp</option>
                    <option value="Outro">Outros</option>
                  </select>
                </div>
                <div className="col-7">
                  <textarea
                    className="form-control"
                    onChange={e => handleFieldChange("content", e.target.value)}
                    required
                  />
                </div>
                <div className="col">
                  <button className="btn btn-sm btn-success mr-2" type="submit">
                    <i className="fas fa-save fa-sm text-white-50"></i>
                    &nbsp;&nbsp;Salvar
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => handleEdit(false)}
                  >
                    <i className="fas fa-ban fa-sm text-white-50"></i>
                    &nbsp;&nbsp;Cancelar
                  </button>
                </div>
              </div>
            </form>
          )}
          <div className="table-responsive-sm">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col" width="200">
                    Data
                  </th>
                  <th scope="col" width="200">
                    Tipo
                  </th>
                  <th scope="col">Anotação</th>
                </tr>
              </thead>
              <tbody>
                {!!contactList &&
                  contactList.map(item => (
                    <tr key={item.id}>
                      <td>
                        {new Date(item.contactDate).toLocaleString("pt-BR")}
                      </td>
                      <td>{item.type}</td>
                      <td>{item.content}</td>
                    </tr>
                  ))}
                {!!contactList === false && <p>Não há mensagens</p>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
