import React from 'react'

export default function VisitResult({ info }) {
    return (
        <div className='card shadow mb-4'>
            <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
                <h6 className='m-0 font-weight-bold text-primary'>Laudo</h6>
            </div>
            <div className='card-body'>
                <div className="input-group">
                    <textarea className="form-control" value={info} disabled></textarea>
                </div>
            </div>
        </div>
    )
}
