import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { isAuthenticated, getRole } from "./services/auth";
import Dashboard from "./pages/Dashboard";
import Schedule from "./pages/Schedule";
import Search from "./pages/Search";
import Products from "./pages/Products";
import Maintenance from "./pages/Maintenance";
import NotFound from "./pages/NotFound";

const isRoleSupported = role => {
  if (getRole().endsWith("ADMIN")) {
    return true;
  }
  const roleFiltered = role().filter(r => r === getRole());
  return roleFiltered.length > 0;
};

const PrivateRoute = ({ component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && isRoleSupported(role) ? (
        <Component {...props} />
      ) : (
        (window.location.href = "/login?logout=true")
      )
    }
  />
);

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <PrivateRoute
          exact
          path="/"
          component={Dashboard}
          role={() => ["BACK_OFFICE", "WORKFORCE"]}
        />
        <PrivateRoute
          path="/search"
          component={Search}
          role={() => ["BACK_OFFICE"]}
        />
        <PrivateRoute
          path="/products"
          component={Products}
          role={() => ["BACK_OFFICE"]}
        />
        <PrivateRoute
          path="/maintenance/:id?"
          component={Maintenance}
          role={() => ["BACK_OFFICE", "WORKFORCE"]}
        />
        <PrivateRoute
          path="/schedule/:id?"
          component={Schedule}
          role={() => ["WORKFORCE"]}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
