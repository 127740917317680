import React from 'react';
import { Link } from 'react-router-dom';

export default class NotFound extends React.Component {

    render() {
        return (
            <div id="wrapper">
                <div className="container d-flex flex-column">
                    <div id="content">
                        <div className="container-fluid">
                            <div className="text-center">
                                <div className="error mx-auto" data-text="404">404</div>
                                <p className="lead text-gray-800 mb-5">Ops! Página não encontrada</p>
                                <p className="text-gray-500 mb-0">A página acessada não existe.</p>
                                <Link to={'/'} >&larr; Voltar ao Dashboard</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
