import React, { Component } from 'react';
import api from '../../services/api';
import { saveAs } from "file-saver";

export default class AttendanceData extends Component {

    state = {
        ticketData: [],
        showData: false
    };

    async getTicketData(id) {
        const response = await api.get(`/service/sales/ticket/${id}`);
        this.setState({ ticketData: response.data });
        (this.state.ticketData.buildings && this.setState({ showData: true }));
    }

    async getVisitForm(id) {
        await api.get(`/service/interconnection/${id}/visitform`, {
            responseType: 'blob',
            headers: { 'Accept': 'application/pdf' }
        }).then(response => {
            if (response.data) {
                const filename = response.headers['content-disposition'].split('"')[1];
                const blob = new Blob([response.data], {
                    type: 'application/pdf',
                });
                saveAs(blob, filename);
            }
        });
    }

    componentWillReceiveProps({ attendanceId }) {
        if (attendanceId !== "") {
            this.getTicketData(attendanceId);
        }
    }

    render() {
        if (this.state.showData) {
            const customerData = this.state.ticketData.salesPersonData;
            const buildingData = this.state.ticketData.buildings;
            return (
                <>
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800 font-weight-bold">Cliente: {customerData.name} </h1>
                        <div onClick={() => { this.getVisitForm(this.state.ticketData.id) }} download="ficha" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Baixar ficha de vistoria</div>
                    </div>
                    <div className="card shadow" >
                        <div className="card-header font-weight-bold text-primary">Dados do cliente</div>
                        <div className="card-body">
                            <ul>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Contato:</p></div>
                                    {customerData.phoneList.map(phone => (
                                        <div className="col-xs-10 pr-2">{`${phone.value} ${(phone.contact !== null && phone.contact !== "") ? ' - ' + phone.contact : ''}`} </div>
                                    ))}
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Endereço:</p></div>
                                    <div className="col-xs-10"> {`${buildingData[0].address.via}, ${buildingData[0].address.number} - ${(buildingData[0].address.compl !== null) ? buildingData[0].address.compl + ' -' : ''} ${buildingData[0].address.district} - ${buildingData[0].address.city} - ${buildingData[0].address.postalCode}`} </div>
                                </li>
                            </ul>
                        </div>
                        <div className="card-header font-weight-bold text-primary">Características da Obra</div>
                        <div className="card-body">
                            <ul>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Estado da Obra:</p></div>
                                    <div className="col-xs-10">{buildingData[0].buildingStatus}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Rede Hidráulica:</p></div>
                                    <div className="col-xs-10">{buildingData[0].waterNetwork}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Tubulação de Água Quente:</p></div>
                                    <div className="col-xs-10">{buildingData[0].hotWaterPipe}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Quantidade de Banheiros:</p></div>
                                    <div className="col-xs-10">{buildingData[0].bathroomQuantity}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Água Quente na Cozinha:</p></div>
                                    <div className="col-xs-10">{buildingData[0].kitchenHotWater}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Banheira(s) individuais:</p></div>
                                    <div className="col-xs-10">{buildingData[0].individualBaths}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Banheira(s) duplas:</p></div>
                                    <div className="col-xs-10">{buildingData[0].doubleBaths}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Anotações:</p></div>
                                    <div className="col-xs-10">{buildingData[0].annotation}</div>
                                </li>
                            </ul>
                        </div>
                        <div className="card-header font-weight-bold text-primary">Dimensionamento Banho</div>
                        <div className="card-body">
                            <ul>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Total banho(s) / dia:</p></div>
                                    <div className="col-xs-10">{buildingData[0].totalShowersForDay}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Vazão média ducha (L/min):</p></div>
                                    <div className="col-xs-10">{buildingData[0].suggestedVolume}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Tempo médio banho (min):</p></div>
                                    <div className="col-xs-10">{buildingData[0].bathTime}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Pontos extras de alto consumo (+100L/ponto):</p></div>
                                    <div className="col-xs-10">{buildingData[0].pointHighCons}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Pontos extras de médio consumo (+20L/ponto):</p></div>
                                    <div className="col-xs-10">{buildingData[0].pointMedCons}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Pontos extras de baixo consumo (+20L/4 pontos):</p></div>
                                    <div className="col-xs-10">{buildingData[0].pointLowCons}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Temperatura alvo (°C):</p></div>
                                    <div className="col-xs-10">{buildingData[0].temperature}</div>
                                </li>
                                <li className="row">
                                    <div className="col-xs-2 pr-1 font-weight-bold"><p>Volume recomendado (L):</p></div>
                                    <div className="col-xs-10">{buildingData[0].suggestedVolume}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </>
            );
        } return (
            <div></div>
        )
    }
}
