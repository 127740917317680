import React from 'react';

export default function AddressData({ postalCode, via, number,
    compl, district, city, state, reference, edit, handleFieldChange, handleSearchCEP }) {
    return (
        <div>
            <div className='row'>
                <label htmlFor='cep' className='col-sm-2 col-form-label'>CEP</label>
                <div className='col-sm-3'>
                    <input type='text' className='form-control mb-1' id='cep'
                        value={postalCode}
                        onChange={(e) => handleFieldChange('postalCode', e.target.value)}
                        disabled={edit} />
                </div>
                {(edit) ? '' : (<button class='d-none d-sm-inline-block btn btn-sm btn-success shadow-sm mb-1'
                    onClick={() => handleSearchCEP(postalCode)}>
                    <i class='fas fa-search fa-sm text-white-50'></i>&nbsp;&nbsp;Buscar CEP</button>)}
            </div>
            <div className='row mb-1'>
                <label htmlFor='via' className='col-sm-2 col-form-label'>Logradouro</label>
                <div className='col-sm-10'>
                    <input type='text' className='form-control' id='via'
                        value={via}
                        onChange={(e) => handleFieldChange('via', e.target.value)}
                        disabled={edit} />
                </div>
            </div>
            <div className='row mb-1'>
                <div class='col'>
                    <div className='row'>
                        <label htmlFor='number' className='col-sm-4 col-form-label'>Número</label>
                        <div className='col-sm-8'>
                            <input type='text' className='form-control' id='number'
                                value={number}
                                onChange={(e) => handleFieldChange('number', e.target.value)}
                                disabled={edit} />
                        </div>
                    </div>
                </div>
                <div class='col'>
                    <div className='row'>
                        <label htmlFor='complement' className='col-sm-4 col-form-label'>Compl.</label>
                        <div className='col-sm-8'>
                            <input type='text' className='form-control' id='complement'
                                value={compl}
                                onChange={(e) => handleFieldChange('compl', e.target.value)}
                                disabled={edit} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-1'>
                <label htmlFor='district' className='col-sm-2 col-form-label'>Bairro</label>
                <div className='col-sm-10'>
                    <input type='text' className='form-control' id='district'
                        value={district}
                        onChange={(e) => handleFieldChange('district', e.target.value)}
                        disabled={edit} />
                </div>
            </div>
            <div className='row mb-1'>
                <div class='col'>
                    <div className='row'>
                        <label htmlFor='city' className='col-sm-4 col-form-label'>Cidade</label>
                        <div className='col-sm-8'>
                            <input type='text' className='form-control' id='city'
                                value={city}
                                onChange={(e) => handleFieldChange('city', e.target.value)}
                                disabled={edit} />
                        </div>
                    </div>
                </div>
                <div class='col'>
                    <div className='row'>
                        <label htmlFor='state' className='col-sm-4 col-form-label'>Estado</label>
                        <div className='col-sm-8'>
                            <input type='text' className='form-control' id='state'
                                value={state}
                                onChange={(e) => handleFieldChange('state', e.target.value)}
                                disabled={edit} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-1'>
                <label htmlFor='reference' className='col-sm-2 col-form-label'>Referência</label>
                <div className='col-sm-10'>
                    <input type='text' className='form-control' id='reference'
                        value={reference}
                        onChange={(e) => handleFieldChange('reference', e.target.value)}
                        disabled={edit} />
                </div>
            </div>
        </div>
    )
}
