import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PhoneInput } from '../../components/MaskedInput';

export default function CreateUserModal({ show, type, isFetching, handleClose, handleFieldChange, handleSubmit, options, error }) {

	return (
		<Modal show={show} onHide={() => handleClose()}>
			<form onSubmit={(e) => handleSubmit(e)}>
				<Modal.Header closeButton>
					<Modal.Title>{(type === 'prospect') ? 'Cadastro de Nova Triagem' : 'Nova Manutenção'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{(error.field === 'other') && (
						<div class="alert alert-warning alert-dismissible fade show" role="alert">
							<strong>Ops!</strong> {error.message}
						</div>
					)}
					<div className='modal-body'>
						<div className={`form-group row ${(type === 'prospect') ? '' : 'd-none'}`}>
							<div className='form-check form-check-inline' >
								<input className='form-check-input' type='radio'
									id='customer'
									name='isProfessional'
									value='false'
									disabled={isFetching}
									onChange={(e) => { handleFieldChange('professional', e.target.value) }}
									defaultChecked />
								<label className='form-check-label' htmlFor='customer'>Cliente</label>
							</div>
							<div className='form-check form-check-inline'>
								<input className='form-check-input' type='radio'
									id='professional'
									name='isProfessional'
									value='true'
									disabled={isFetching}
									onChange={(e) => { handleFieldChange('professional', e.target.value) }} />
								<label className='form-check-label' htmlFor='professional'>Profissional</label>
							</div>
						</div>
						<div className='form-group row'>
							<div className='col'>
								<label htmlFor='name'>Nome</label>
								<input className={`form-control form-control-sm ${(error.field === 'name') ? 'is-invalid' : ''}`}
									type='text'
									id='name'
									name='name'
									placeholder='Nome'
									disabled={isFetching}
									onChange={(e) => { handleFieldChange('firstName', e.target.value) }}
									required />
								<div className='invalid-feedback'>{error.message}</div>
							</div>
							<div className='col'>
								<label htmlFor='lastname'>Sobrenome</label>
								<input className={`form-control form-control-sm ${(error.field === 'name') ? 'is-invalid' : ''}`}
									type='text'
									id='lastname'
									name='lastname'
									placeholder='Sobrenome'
									disabled={isFetching}
									onChange={(e) => { handleFieldChange('lastName', e.target.value) }}
									required />
							</div>
						</div>
						<div className='form-group'>
							<label htmlFor='email'>E-mail</label>
							<input className={`form-control form-control-sm ${(error.field === 'email') ? 'is-invalid' : ''}`}
								type='email'
								id='email'
								name='email'
								placeholder='E-mail'
								disabled={isFetching}
								onChange={(e) => { handleFieldChange('email', e.target.value) }} />
							<div className='invalid-feedback'>{error.message}</div>
						</div>
						<div className='form-group row'>
							<div className='col-sm-6'>
								<label htmlFor='telephone'>Telefone</label>
								<PhoneInput className={`form-control form-control-sm ${(error.field === 'telephone') ? 'is-invalid' : ''}`}
									type='text'
									id='telephone'
									name='telephone'
									disabled={isFetching}
									onChange={(e) => { handleFieldChange('telephone', e.target.value) }} />
								<div className='invalid-feedback'>{error.message}</div>
							</div>
						</div>
						<div className='form-group'>
							<label htmlFor='date'>Data da Triagem
                				<input className='form-control form-control-sm' type='date'
									id='date'
									name='date'
									disabled={isFetching}
									onChange={(e) => { handleFieldChange('prospectDate', e.target.value) }}
									required />
							</label>
						</div>
						<div className='form-group'>
							<label>Fonte da Triagem &nbsp;
                                <select onChange={(e) => { handleFieldChange('prospectSourceOption', e.target.value) }} required>
									<option></option>
									{options.prospectSourceOptions.map((opt) => (
										<option value={opt.name} key={opt.id}>{opt.name}</option>
									))}
								</select>
							</label>
						</div>
						{(type === 'prospect') ? (
							<div className='form-group'>
							<label>Representante &nbsp;
                                <select onChange={(e) => { handleFieldChange('salesman', e.target.value) }} required>
									<option></option>
									{options.systemUserOptions.map((seller) => (
										(!!seller.id && <option value={seller.name} key={seller.id}>{seller.name}</option>)
									))}
								</select>
							</label>
						</div>
						) : ''}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary'
						disabled={isFetching}
						onClick={() => handleClose()}>Cancelar</Button>
					<Button variant='primary'
						disabled={isFetching}
						type='submit'>Salvar</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}
