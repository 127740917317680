import React, { Component } from "react";
import PageContainer from "../../components/PageContainer";
import MessageHistory from "./MessageHistory";
import Schedule from "./Schedule";
import PersonData from "./PersonData";
import AddressData from "./AddressData";
import VisitResult from "./VisitResult";
import ServiceInfo from "./ServiceInfo";
import Cardbox from "./components/Cardbox";
import api from "../../services/api";
import correios from "../../services/correios";

export default class Maintenance extends Component {
  constructor() {
    super();
    this.state = {
      isFetching: false,
      options: null,
      timeRange: [],
      maintenanceId: null,
      personData: {
        edit: false,
        id: "",
        name: "",
        document: "",
        document2: "",
        email: "",
        phoneList: [],
        prospectDate: "",
        prospectSourceOption: "",
        address: {
          id: "",
          postalCode: "",
          via: "",
          number: "",
          compl: "",
          district: "",
          city: "",
          state: "",
          reference: ""
        }
      },
      buildingData: {
        id: null,
        technicalVisit: {
          dateTo: "",
          timeTo: "",
          date: null,
          report: null,
          interconnectionEquip: {
            id: null,
            description: null
          }
        },
        address: {
          edit: false,
          addressId: "",
          postalCode: "",
          via: "",
          number: "",
          compl: "",
          district: "",
          city: "",
          state: "",
          reference: ""
        }
      },
      contacts: {
        list: [],
        type: null,
        content: null,
        infoId: null,
        infoContent: null,
        edit: false,
        editinfo: false
      }
    };
  }

  componentWillMount() {
    api.get(`/service/interconnection/equip`).then(response => {
      if (response.status === 200) {
        this.setState({ options: response.data });
      }
    });
    api.get(`/service/interconnection/times`).then(response => {
      if (response.status === 200) {
        this.setState({ timeRange: response.data });
      }
    });
  }

  componentDidMount() {
    this.setState({ maintenanceId: this.props.match.params.id });
    api
      .get(`/service/maintenances/${this.props.match.params.id}`)
      .then(response => {
        const contactsFiltered = response.data.contacts.filter(
          c => c.type !== "Info"
        );
        const infoData = response.data.contacts.filter(
          c => c.type === "Info"
        )[0];
        if (response.status === 200) {
          this.setState({
            ...this.state,
            personData: {
              ...this.state.personData,
              ...response.data.personData
            },
            buildingData: {
              ...this.state.buildingData,
              id: response.data.buildingData.id,
              technicalVisit: {
                ...this.state.buildingData.technicalVisit,
                ...response.data.buildingData.technicalVisit
              },
              address: {
                ...this.state.buildingData.address,
                ...response.data.buildingData.address
              }
            },
            contacts: {
              ...this.state.contacts,
              list: contactsFiltered,
              infoId: !!infoData ? infoData.id : null,
              infoContent: !!infoData ? infoData.content : null
            }
          });
        }
      });
  }

  handleFieldChange(data, field, value) {
    if (data === "contacts") {
      this.setState({ contacts: { ...this.state.contacts, [field]: value } });
    }
    if (data === "personData") {
      this.setState({
        personData: { ...this.state.personData, [field]: value }
      });
    }
    if (data === "personData.address") {
      this.setState({
        personData: {
          ...this.state.personData,
          address: { ...this.state.personData.address, [field]: value }
        }
      });
    }
    if (data === "address") {
      this.setState({
        buildingData: {
          ...this.state.buildingData,
          address: { ...this.state.buildingData.address, [field]: value }
        }
      });
    }
    if (data === "maintenance") {
      this.setState({
        maintenance: { ...this.state.maintenance, [field]: value }
      });
    }
    if (data === "buildingData") {
      this.setState({
        buildingData: {
          ...this.state.buildingData,
          technicalVisit: {
            ...this.state.buildingData.technicalVisit,
            [field]: value
          }
        }
      });
    }
  }

  handleEdit(component, status) {
    const show = status === true;
    if (component === "messages") {
      this.setState({ messages: { ...this.state.messages, edit: show } });
    }
    if (component === "address") {
      this.setState({
        address: {
          ...this.state.address,
          disabled: !this.state.buildingData.address.edit
        }
      });
    }
    if (component === "personData") {
      this.setState({
        personData: {
          ...this.state.personData,
          disabled: !this.state.personData.disabled
        }
      });
    }
  }

  async handleUpdateContacts(e) {
    e.preventDefault();
    const contacts = this.state.contacts;
    const headers = { "Content-Type": "application/json" };
    const postData = {
      contactDate: new Date().toISOString(),
      content: contacts.content,
      type: contacts.type
    };
    this.setState({ isFetching: true });

    try {
      await api
        .post(
          `/service/sales/ticket/${this.state.maintenanceId}/contact/registry`,
          postData,
          { headers: headers }
        )
        .then(response => {
          const data = response.data;
          const newMessage = {
            id: data.id,
            contactDate: data.contactDate,
            type: data.type,
            content: data.content
          };
          contacts.list.unshift(newMessage);
          this.setState({
            contacts: {
              ...this.state.contacts,
              list: contacts.list,
              type: "",
              text: "",
              edit: false
            }
          });
        });
    } catch (error) {
      console.log(error);
      this.setState({
        fieldError: {
          field: "other",
          message: error.response.data.message
        }
      });
    }
  }

  async handleUpdateInfo(e) {
    e.preventDefault();
    const contacts = this.state.contacts;
    const headers = { "Content-Type": "application/json" };
    const postData = {
      contactDate: new Date().toISOString(),
      content: contacts.infoContent,
      type: "Info"
    };
    const consumeApi = !!contacts.infoId
      ? (data, headers) =>
          api.put(
            `/service/sales/ticket/${this.state.maintenanceId}/contact/registry/${contacts.infoId}`,
            data,
            headers
          )
      : (data, headers) =>
          api.post(
            `/service/sales/ticket/${this.state.maintenanceId}/contact/registry`,
            data,
            headers
          );
    this.setState({ isFetching: true });

    try {
      await consumeApi(postData, { headers: headers }).then(response => {
        const data = response.data;
        this.setState({
          contacts: {
            ...this.state.contacts,
            infoId: data.id,
            infoContent: data.content,
            editinfo: false
          }
        });
      });
    } catch (error) {
      console.log(error);
      this.setState({
        fieldError: {
          field: "other",
          message: error.response.data.message
        }
      });
    }
  }

  handleUpdateSchedule(e) {
    e.preventDefault();
    const headers = { "Content-Type": "application/json" };
    const postData = {
      address: {
        ...this.state.buildingData.address,
        id: this.state.buildingData.address.addressId
      },
      technicalVisit: {
        interconnectionEquip: {
          id: 134,
          description: "Solar Service"
        },
        date: "1579807800000",
        status: "ACTIVE",
        createEmail: false,
        createEmailBath: false,
        createEmailPool: false
      }
    };
    console.log("PostData: ", postData);
    console.log("State: ", this.state);

    try {
      api
        .put(
          `/service/sales/ticket/${this.state.maintenanceId}/building/${this.state.buildingData.id}`,
          postData,
          { headers: headers }
        )
        .then(response => {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
      this.setState({
        fieldError: {
          field: "other",
          message: error.response.data.message
        }
      });
    }
    this.setState({
      personData: { ...this.state.personData, edit: false }
    });
  }

  async handleUpdatePersonData() {
    const headers = { "Content-Type": "application/json" };
    const postData = this.state.personData;
    this.setState({ isFetching: true });

    try {
      await api
        .put(
          `/service/sales/ticket/${this.state.maintenanceId}/person/${this.state.personData.id}`,
          postData,
          { headers: headers }
        )
        .then(response => {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
      this.setState({
        fieldError: {
          field: "other",
          message: error.response.data.message
        }
      });
    }
    this.setState({
      personData: { ...this.state.personData, edit: false }
    });
  }

  async handleUpdateAddress() {
    const headers = { "Content-Type": "application/json" };
    const postData = {
      personData: { ...this.state.personData },
      buildingData: {
        address: { ...this.state.buildingData.address }
      }
    };
    this.setState({ isFetching: true });

    try {
      await api
        .post(
          `/service/maintenances/${this.state.maintenanceId}/build`,
          postData,
          { headers: headers }
        )
        .then(response => {
          const address = response.data.address;
          this.setState({
            address: { ...address }
          });
        });
    } catch (error) {
      console.log(error);
      this.setState({
        fieldError: {
          field: "other",
          message: error.response.data.message
        }
      });
    }
    this.setState({
      buildingData: { ...this.state.buildingData, edit: false }
    });
  }

  searchCEPPerson(postalcode) {
    correios.get(`${postalcode}/json/`).then(response => {
      if (response.status === 200) {
        this.setState({
          personData: {
            ...this.state.personData,
            address: {
              ...this.state.personData.address,
              postalCode: postalcode,
              via: response.data.logradouro,
              number: "",
              complement: response.data.complemento,
              district: response.data.bairro,
              city: response.data.localidade,
              state: response.data.uf,
              reference: ""
            }
          }
        });
      }
    });
  }

  searchCEPBuild(postalcode) {
    correios.get(`${postalcode}/json/`).then(response => {
      if (response.status === 200) {
        this.setState({
          buildingData: {
            ...this.state.buildingData,
            address: {
              ...this.state.buildingData.address,
              postalCode: postalcode,
              via: response.data.logradouro,
              number: "",
              complement: response.data.complemento,
              district: response.data.bairro,
              city: response.data.localidade,
              state: response.data.uf,
              reference: ""
            }
          }
        });
      }
    });
  }

  render() {
    console.log("Estado", this.state);
    return (
      <PageContainer>
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <div>
              <h1 className="h3 mb-0 text-gray-800">
                {this.state.personData.name}
              </h1>
              <p className="small">
                Cliente desde{" "}
                {new Date(
                  this.state.personData.prospectDate
                ).toLocaleDateString("pt-BR")}
              </p>
            </div>
            <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
              <i class="fas fa-download fa-sm text-white-50"></i> Baixar ficha
              de manutenção
            </button>
          </div>
          <div className="row">
            <MessageHistory
              contactList={this.state.contacts.list}
              edit={!!this.state.contacts.edit}
              handleEdit={status =>
                this.setState({
                  contacts: { ...this.state.contacts, edit: status }
                })
              }
              handleFieldChange={(field, value) =>
                this.handleFieldChange("contacts", field, value)
              }
              handleUpdate={e => this.handleUpdateContacts(e)}
            />
            <Schedule
              options={this.state.options}
              visitDate={this.state.buildingData.technicalVisit.date}
              selectedEquip={
                this.state.buildingData.technicalVisit.interconnectionEquip
              }
              timeRange={this.state.timeRange}
              handleFieldChange={(field, value) =>
                this.handleFieldChange("`buildingData`", field, value)
              }
              handleUpdate={e => this.handleUpdateSchedule(e)}
            />
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <PersonData
                {...this.state.personData}
                title={"Dados do cliente"}
                edit={this.state.personData.edit}
                handleEdit={status =>
                  this.setState({
                    personData: { ...this.state.personData, edit: status }
                  })
                }
                handleFieldChange={(field, value) =>
                  this.handleFieldChange("personData", field, value)
                }
                handleAddressFieldChange={(field, value) =>
                  this.handleFieldChange("personData.address", field, value)
                }
                handleSave={() => this.handleUpdatePersonData()}
                handleSearchCEP={postalcode => this.searchCEPPerson(postalcode)}
              />
              <Cardbox
                title={"Endereço"}
                edit={this.state.buildingData.address.edit}
                handleEdit={status =>
                  this.setState({
                    buildingData: {
                      ...this.state.buildingData,
                      address: {
                        ...this.state.buildingData.address,
                        edit: status
                      }
                    }
                  })
                }
                handleSave={() => this.handleUpdateAddress()}
              >
                <AddressData
                  {...this.state.buildingData.address}
                  edit={!this.state.buildingData.address.edit}
                  handleFieldChange={(field, value) =>
                    this.handleFieldChange("address", field, value)
                  }
                  handleSearchCEP={postalcode =>
                    this.searchCEPBuild(postalcode)
                  }
                />
              </Cardbox>
            </div>
            <div className="col-xl-6 col-lg-6">
              <ServiceInfo
                info={this.state.contacts.infoContent}
                edit={this.state.contacts.editinfo}
                handleEdit={status =>
                  this.setState({
                    contacts: {
                      ...this.state.contacts,
                      editinfo: status
                    }
                  })
                }
                handleFieldChange={(field, value) =>
                  this.handleFieldChange("contacts", field, value)
                }
                handleUpdate={e => {
                  this.handleUpdateInfo(e);
                }}
              />
              <VisitResult
                startDate={new Date(this.state.buildingData.technicalVisit.date)
                  .toLocaleTimeString("pt-BR")
                  .substr(0, 5)}
                finishDate={""}
                info={this.state.buildingData.technicalVisit.report}
              />
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}
