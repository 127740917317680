import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logoff } from '../../services/auth';


class LogoutModal extends Component {

    signout() {
        logoff();
        window.location.href = '/login?logoff=true';
    }

    render() {
        return (
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Você realmente quer saír?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Selecione "Sair" abaixo para finalizar sua sessão.</div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                            <button className="btn btn-primary" data-dismiss="modal" onClick={this.signout.bind(this)} >Sair</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(LogoutModal)