import React from 'react';
import { PhoneInput } from '../../components/MaskedInput';

export default function SearchUser({ isFeching, handleSearch, handleFieldChange, searchError }) {

    return (
        <div className="row mb-4">
            <form onSubmit={(e) => { handleSearch(e) }}>
                <div className="col input-group">
                    <input type="text" className="form-control bg-light border-0 small"
                        name='name'
                        disabled={isFeching}
                        placeholder="Nome"
                        onChange={(e) => handleFieldChange('name', e.target.value)} />

                    <input type="text" className="form-control bg-light border-0 small border-left-primary"
                        name='email'
                        disabled={isFeching}
                        placeholder="E-mail"
                        onChange={(e) => handleFieldChange('email', e.target.value)} />

                    <PhoneInput type="text" className="form-control bg-light border-0 small border-left-primary"
                        name='telephone'
                        disabled={isFeching}
                        placeholder="Telefone"
                        onChange={(e) => handleFieldChange('telephone', e.target.value)} />

                    <div className="input-group-append">
                        <button className="btn btn-primary" type="submit" disabled={isFeching} >
                            <i className="fas fa-search fa-sm" />&nbsp;Buscar
                        </button>
                    </div>
                </div>
            </form>
            {(!!searchError && (
                <div class={`alert alert-${(!!searchError.type) ? searchError.type : 'danger'} small`} role="alert">
                    <span className="mr-4">{searchError.message}</span>
                </div>
            ))}
        </div>
    );
}
