import React from 'react';
import PageContainer from '../../components/PageContainer';
import logo from '../../assets/logo_fundo_transparente.png'

export default function Dashboard() {
    return (
        <PageContainer>
            <div className='d-flex justify-content-center'>
                <img className='img-responsive' src={logo} alt='Solarbraz' />
            </div>
        </PageContainer>
    );
}
