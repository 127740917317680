import React from 'react';
import { Link } from 'react-router-dom';

const NavButton = (props) => (
    <li className={`nav-item ${(props.active === props.children) ? 'active' : ''}`}>
        <Link className='nav-link' to={props.linkto}><i className={`fas fa-fw fa-${props.icon}`}></i><span>{props.children}</span></Link>
    </li>
);

export default NavButton;
