import React from "react";
import AddressData from "./AddressData";
import Cardbox from "./components/Cardbox";

export default function PersonData({
  title,
  edit,
  handleEdit,
  handleSave,
  name,
  email,
  phoneList,
  document,
  document2,
  address,
  handleSearchCEP,
  handleFieldChange,
  handleAddressFieldChange
}) {
  return (
    <Cardbox
      title={title}
      edit={edit}
      handleEdit={handleEdit}
      handleSave={handleSave}
    >
      <div>
        <div className="row">
          <label htmlFor="name" className="col-sm-2 col-form-label">
            Nome
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control mb-1"
              id="name"
              value={name}
              onChange={e => handleFieldChange("name", e.target.value)}
              disabled={!edit}
            />
          </div>
        </div>
        <div className="row">
          <label htmlFor="email" className="col-sm-2 col-form-label">
            Email
          </label>
          <div className="col-sm-10">
            <input
              type="email"
              className="form-control mb-1"
              id="email"
              value={email}
              onChange={e => handleFieldChange("email", e.target.value)}
              disabled={!edit}
            />
          </div>
        </div>
        {phoneList.map(phone => (
          <div className="row mb-1" key={phone.id}>
            <div class="col">
              <div className="row">
                <label htmlFor="telephone" className="col-sm-4 col-form-label">
                  Telefone
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    value={phone.value}
                    onChange={e =>
                      handleFieldChange("phoneList", e.target.value)
                    }
                    disabled={!edit}
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <div className="row">
                {/*
                                <label htmlFor='contact' className='col-sm-4 col-form-label'>Contato</label>
                                <div className='col-sm-8'>
                                    <input type='text' className='form-control' id='complement'
                                        value={phone.contact}
                                        onChange={(e) => handleFieldChange('complement', e.target.value)}
                                        disabled={!edit} />
                                </div>
                                */}
              </div>
            </div>
          </div>
        ))}
        <div className="row mb-1">
          <div class="col">
            <div className="row">
              <label htmlFor="cpfcnpj" className="col-sm-4 col-form-label">
                CPF / CNPJ
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="cpfcnpj"
                  value={document}
                  onChange={e => handleFieldChange("document", e.target.value)}
                  disabled={!edit}
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div className="row">
              <label htmlFor="rg" className="col-sm-2 col-form-label">
                RG
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="rg"
                  value={document2}
                  onChange={e => handleFieldChange("document2", e.target.value)}
                  disabled={!edit}
                />
              </div>
            </div>
          </div>
        </div>
        <AddressData
          {...address}
          edit={!edit}
          handleFieldChange={(field, value) =>
            handleAddressFieldChange(field, value)
          }
          handleSearchCEP={postalcode => handleSearchCEP(postalcode)}
        />
      </div>
    </Cardbox>
  );
}
