import React from 'react';
import { MDBDataTable } from 'mdbreact';

export default function ResultTable({ resultData, headerText, primaryActionButtonTitle, secundaryActionButtonTitle, handleActionButton, small, order, entries }) {
    return (
        <div className="row">
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-sm-flex align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">{(!!headerText) ? headerText : 'Resultado da Busca'}</h6>
                    {(!!primaryActionButtonTitle && (
                        <span>
                            <button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-2"
                                onClick={() => handleActionButton('prospect')}>
                                <i className="fas fa-plus fa-sm text-white-50" /> {primaryActionButtonTitle}
                            </button>
                            <button className="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
                                onClick={() => handleActionButton('maintenance')}>
                                <i className="fas fa-plus fa-sm text-white-50" /> {secundaryActionButtonTitle}
                            </button>
                        </span>
                    ))}
                </div>
                <div className="card-body">
                    <div className={`table ${(small) ? 'small' : ''}`}>
                        {<MDBDataTable
                            fixed
                            striped
                            bordered
                            hover
                            reponsive
                            small
                            order={order}
                            entries={entries}
                            entriesLabel="Quantidade de resultados"
                            paginationLabel={["Anterior", "Próximo"]}
                            searchLabel="Buscar por"
                            infoLabel={["Mostrando", "de", "de", "resultados"]}
                            data={resultData}
                        />}
                    </div>
                </div>
            </div>
        </div>
    )
}
