import React from 'react'
import { Link } from 'react-router-dom'
import NavButton from './NavButton'
import { getFunction } from '../../services/roleFunctions'
import { getRole } from '../../services/auth'

const Sidebar = () => {
  const functionList = getFunction(getRole())
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      id="accordionSidebar"
    >
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-sun"></i>
        </div>
        <div className="sidebar-brand-text mx-3">Solabraz</div>
      </Link>
      <hr className="sidebar-divider my-0" />
      {functionList.map(item => (
        <NavButton linkto={item.link} icon={item.icon}>
          {item.name}
        </NavButton>
      ))}
      <hr className="sidebar-divider d-none d-md-block" />
      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </ul>
  )
}

export default Sidebar
