import React, { Component } from 'react';
import PageContainer from '../../components/PageContainer';
import ResultTable from '../../components/ResultTable';
import api from '../../services/api';


export default class Products extends Component {

    constructor() {
        super();
        this.state = {
            productData: null
        }
    }

    componentWillMount() {
        api.get(`/service/quote/products`)
            .then((response) => {
                if (response.status === 200) {
                    const productData = this.buildTableData(response.data.products);
                    console.log(productData);
                    this.setState({ productData: productData });
                }
            });
    }

    buildTableData(data) {
        return {
            columns: [
                { label: 'Cod', field: 'cod', attributes: { "width": "60px" } },
                { label: 'Descrição', field: 'description', sort: 'asc', attributes: { "width": "800px" } },
                { label: 'Tipo', field: 'type', attributes: { "width": "250" } },
                { label: 'Marca', field: 'brand', attributes: { "width": "150" } },
                { label: 'Unidade', field: 'unit', attributes: { "width": "100" } },
                { label: 'Preço', field: 'price', attributes: { "width": "100" } }
            ],
            rows: data.map((item) => ({
                cod: item.cod,
                description: item.description,
                type: this.getTypeFromId(item.type),
                brand: this.getBrandFromId(item.brand),
                unit: item.unit,
                price: item.price.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
            }))
        };
    }

    getTypeFromId(id) {
        let brand = '';
        switch (id) {
            case 'THERMAL_RESERVATORY':
                brand = "Reservatório Térmico";
                break;
            case 'COLLECTOR':
                brand = "Coletor";
                break;
            case 'ADDITIONAL_KIT':
                brand = "Kit Adicional";
                break;
            case 'UNDEFINED':
                brand = "Indefinido";
                break;
            default:
                brand = "";
                break;
        }
        return brand;
    }

    getBrandFromId(id) {
        let type = '';
        switch (id) {
            case 1:
                type = "Soletrol";
                break;
            case 13:
                type = "Heliodin";
                break;
            case 25:
                type = "Solarem";
                break;
            case 29:
                type = "Mastersol";
                break;
            default:
                type = "";
                break;
        }
        return type;
    }

    render() {
        return (
            <PageContainer>
                <div className='container-fluid'>
                    <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                        <h1 className='h3 mb-0 text-gray-800'>Produtos</h1>
                    </div>
                    {(!!this.state.productData && <ResultTable
                        headerText='Lista de Produtos'
                        small
                        entries={100}
                        resultData={this.state.productData} />)}
                </div>
            </PageContainer >
        );
    }
}
