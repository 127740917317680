import React from 'react';
import masks from './masks';

export const PhoneInput = ({ onChange: incommingOnChange, ...props }) => (
    <input {...props} onChange={(e) => incommingOnChange(replaceWithMask(e, masks.phone))} />
);

export const removeMask = (value) => {
    return value
        .replace((/[.\- ,(|)]/gi), '')
}

const replaceWithMask = (e, mask) => {
    e.target.value = mask(e.target.value);
    return e;
} 
