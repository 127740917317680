import React from 'react';
import PageContainer from '../../components/PageContainer';
import api from '../../services/api';
import CardListHeader from './CardListHeader';
import CardListItem from './CardListItem';
import AttendanceData from './AttendanceData';

export default class Dashboard extends React.Component {

    state = {
        schedule: [],
        ticketId: ""
    };

    componentWillMount() {
        const getSchedule = async () => {
            const response = await api.get(`/service/interconnection/schedule`);
            this.setState({ schedule: response.data.salesCustomerDayList });
        }
        getSchedule();
        (this.props.match.params.id && this.setState({ticketId: this.props.match.params.id}));
    }

    render() {
        return (
            <PageContainer>
                <div className="container-fluid">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <div className="col-md-8"><h1 className="h3 mb-0 text-gray-800">Agenda</h1></div>
                        <div className="col-md-4 text-right">
                            <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control border-0 small" placeholder="Busca..." aria-label="Search" aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            {
                                this.state.schedule.map(schedule => {
                                    return (
                                        <CardListHeader date={schedule.date}>
                                            {
                                                schedule.salesCustomerList.map(attendance => (
                                                        <div onClick={()=>{this.setState({ticketId: attendance.id})}}>
                                                            <CardListItem
                                                            name={attendance.name}
                                                            ticketId={attendance.id}
                                                            attendanceDate={attendance.date}
                                                            attendanceType={attendance.installationType} />
                                                        </div>
                                                    )
                                                )
                                            }
                                        </CardListHeader>
                                    )
                                })
                            }
                        </div>
                        <div className="col-md-9">
                            <AttendanceData attendanceId={this.state.ticketId}/>
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}
