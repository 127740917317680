import React from "react";

export default function Schedule({
  options,
  visitDate,
  timeRange,
  selectedEquip,
  handleFieldChange,
  handleUpdate
}) {
  const today = new Date().toISOString().substr(0, 10);
  const selectedEquipId = !!selectedEquip.id ? selectedEquip.id : "";
  return (
    <div className="col-xl-4 col-lg-4">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">Agendamento</h6>
          <button
            class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            onClick={e => handleUpdate(e)}
          >
            <i class="fas fa-save fa-sm text-white-50"></i>&nbsp;&nbsp;Salvar
          </button>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Data da visita</label>
            <div className="col-sm-8">
              <input
                type="date"
                className="form-control"
                value={
                  !!visitDate
                    ? new Date(visitDate).toISOString().substr(0, 10)
                    : today
                }
                onChange={e => handleFieldChange("dateTo", e.target.value)}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Horário</label>
            <div className="col-sm-8">
              <select
                class="custom-select mr-sm-4"
                onChange={e => handleFieldChange("timeTo", e.target.value)}
                /**
                value={
                  !!visitDate
                    ? new Date(visitDate).toISOString().substr(11, 15)
                    : new Date(today).toISOString().substr(11, 15) */
              >
                <option></option>
                {timeRange.length > 0 &&
                  timeRange.map((tr, i) => (
                    <option
                      value={tr}
                      key={i}
                      selected={
                        tr === new Date(visitDate).toISOString().substr(11, 5)
                      }
                    >
                      {tr}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Instaladora</label>
            <div className="col-sm-8">
              <select
                class="custom-select mr-sm-4"
                onChange={e =>
                  handleFieldChange(
                    "interconnectionEquip",
                    options.filter(opt => opt.description === e.target.value)
                  )
                }
              >
                <option></option>
                {!!options &&
                  options.map(opt => (
                    <option
                      value={opt.description}
                      key={opt.id}
                      selected={opt.id === selectedEquipId}
                    >
                      {opt.description}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {/*
                    <div className='form-group row'>
                        <label htmlFor='value' className='col-sm-3 col-form-label'>Valor</label>
                        <div className='col-sm-8'>
                            <input type='text' className='form-control' id='value'
                                value={(!!value) ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}
                                onChange={(e) => handleFieldChange('value', e.target.value)} />
                        </div>
                    </div>
                    */}
        </div>
      </div>
    </div>
  );
}
