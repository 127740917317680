import React from 'react';

const CardListHeader = ({ date, children }) => {
    let dateFormatted = new Date(date).toLocaleDateString();
    return (
        <div className="card shadow mb-2" key={date}>
            <a href={`#collapse${date}`} className="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true">
                <h6 className="m-0 font-weight-bold text-primary"><i className="fas fa-calendar fa-1x mr-2"></i>{dateFormatted}</h6>
            </a>
            <div className="colapse show" id={`collapse${date}`}>
                <div className="list-group">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default CardListHeader;
