import React from 'react';
import { getToken } from '../../services/auth';
import usericon from '../../assets/usericon.png';
// import Alerts from './Alerts';
// import MessageCenter from './MessageCenter';

export default class Header extends React.Component {

    state = {
        userdata: JSON.parse(getToken())
    }

    render() {
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>
                <ul className="navbar-nav ml-auto">
                    { /* <Alerts/> <MessageCenter/> */}
                    <li className="nav-item">
                        <a className='nav-link'
                        href="https://drive.google.com/open?id=0B5uRQ1PbST6NbFRPc0tIck9DNlE"
                        rel="noopener noreferrer"
                        target="_blank">
                            <span class="mr-2 d-none d-lg-inline text-gray-600 small"><i className='fas fa-external-link-alt fa-1x' /> Documentos</span>
                        </a>
                    </li>
                    <li className="nav-item" >
                        <a className='nav-link'
                        href={`/${this.state.userdata.adminPath}`}
                        rel="noopener noreferrer"
                        target="_blank" >
                            <span class="mr-2 d-none d-lg-inline text-gray-600 small"><i className='fas fa-tasks fa-1x' /> Admin</span>
                        </a>
                    </li>
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="# " id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{this.state.userdata.name}</span>
                            <img className="img-profile rounded-circle" src={usericon} alt="" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="# " data-toggle="modal" data-target="#logoutModal">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        );
    }
}
