import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import SearchUser from '../../components/SearchUser';
import ResultTable from '../../components/ResultTable';
import CreateUserModal from './CreateUserModal';
import { EditableTextField, EditableSelectField } from '../../components/EditableField/EditableField';
import api from '../../services/api';
import masks from '../../components/MaskedInput/masks';
import { removeMask } from '../../components/MaskedInput';

export default class Search extends Component {

    constructor() {
        super();
        this.state = {
            options: null,
            isFetching: false,
            searchResult: null,
            searchData: {
                name: '',
                email: '',
                telephone: ''
            },
            searchError: null,
            showModal: null,
            newPersonData: {
                firstName: '',
                lastName: '',
                email: '',
                telephone: '',
                professional: false,
                prospectDate: null,
                prospectSourceOption: {},
                salesman: {},
            },
            fieldError: {}
        }
    }

    componentWillMount() {
        api.get(`/service/backoffice/prospect/options`)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ options: response.data });
                }
            });
    }

    handleFieldChange(data, field, value) {
        if (data === 'searchData') {
            this.setState({ searchData: { ...this.state.searchData, [field]: value } });
        }
        if (data === 'newPersonData') {
            this.setState({ newPersonData: { ...this.state.newPersonData, [field]: value } });
        }
    }

    handleSearch(e) {
        e.preventDefault();
        if (!(!!this.state.searchData.name || !!this.state.searchData.email || !!this.state.searchData.telephone)) {
            this.setState({ searchError: { type: 'warning', message: 'Você precisa preencher pelo menos um campo na busca.' } });
            return;
        }

        const name = (!!this.state.searchData.name ? this.state.searchData.name : '');
        const email = (!!this.state.searchData.email ? this.state.searchData.email : '');
        const telephone = removeMask(!!this.state.searchData.telephone ? this.state.searchData.telephone : '');
        this.setState({ isFetching: true, searchError: null, searchResult: null });

        try {
            api.get(`/service/backoffice/person/all?name=${name}&email=${email}&telephone=${telephone}`)
                .then((response) => {
                    const dataTable = this.buildTableData(response.data);
                    this.setState({ searchResult: dataTable, searchError: null });
                    this.setState({ isFetching: false });
                });
        } catch (error) {
            console.log(error);
            if (error.response) {
                this.setState({
                    searchError: { type: 'danger', message: error.response.data.message },
                    isFetching: false
                });
            }
        }
    }

    async handleTableFieldUpdate(field, value, personId, objectId) {
        console.log(field, value);
        const headers = { 'Content-Type': 'application/json', };
        const postData = { field: field, objectId: objectId, value: value };
        try {
            await api.put(`/service/backoffice/person/${personId}`, postData, { headers: headers })
                .then((response) => {
                    this.setState({ searchError: { type: 'success ', message: 'Alteração efetuada' } });
                });
            return true;
        } catch (error) {
            console.log(error);
            this.setState({ searchError: { type: 'danger ', message: 'Houve um erro ao atualizar' } });
            return false;
        };
    }

    buildTableData(data) {

        const sortedData = data.sort(function (a, b) {
            return new Date(b.prospectDate) - new Date(a.prospectDate);
        });

        return {
            columns: [
                { label: '', field: 'professional', attributes: { 'width': '20px' } },
                { label: 'Nome', field: 'name', sort: 'asc', attributes: { 'width': '400px' } },
                { label: 'E-mail', field: 'email', attributes: { 'width': '300px' } },
                { label: 'Telefone', field: 'phone', attributes: { 'width': '200' } },
                { label: 'Fonte da Triagem', field: 'source', attributes: { 'width': '250' } },
                { label: 'Data da triagem', field: 'prospectDate', attributes: { 'width': '200' } },
                { label: 'Representante', field: 'salesMan', attributes: { 'width': '300' } },
                { label: 'Ações', field: 'actions', attributes: { 'width': '70' } }
            ],
            rows: sortedData.map((item) => {
                const {
                        attendanceTicketId,
                        professional,
                        maintenanceTicketId
                      } = item;
                let actions = [],
                    salesAttendanceLink = attendanceTicketId ? `/crm/#!/sales/attendance/${item.attendanceTicketId}` : undefined;
                if(attendanceTicketId && professional)
                    salesAttendanceLink = `/crm/#!/sales/attendance/professional/${item.attendanceTicketId}`;
                if(salesAttendanceLink)
                    actions.push(salesAttendanceLink);
                return {
                    professional: <i className={`fas fa-${(item.professional) ? 'user' : 'building'} fa-1x`}></i>,
                    name: <span className='nobreak small'><EditableTextField
                        field={'name'}
                        value={item.name}
                        handleSave={(field, value) => this.handleTableFieldUpdate(field, value, item.personId, item.personId)} /></span>,
                    email: <span className='nobreak small'><EditableTextField
                        field={'email'}
                        value={item.email}
                        handleSave={(field, value) => this.handleTableFieldUpdate(field, value, item.personId, item.emailId)} /></span>,
                    phone: masks.phone(item.tel),
                    source: item.source,
                    prospectDate: new Date(item.prospectDate).toLocaleDateString(),
                    salesMan: <span className='nobreak small'><EditableSelectField
                    field={'salesManUserId'}
                    value={{ id: item.salesManUserId, name: item.salesMan }}
                    itens={this.state.options.systemUserOptions}
                    handleSave={(field, value) => this.handleTableFieldUpdate(field, value.id, item.personId, item.attendanceTicketId)} /></span>,
                    actions: <span className='nobreak small'>{(salesAttendanceLink)
                        ? <a className='mr-2'
                            href={ salesAttendanceLink }
                            rel='noopener noreferrer'
                            target='_blank'>
                            <i className={'fas fa-external-link-alt'} /></a>
                        : <i className={'fas fa-plus'} />}
                        {(maintenanceTicketId)
                            ? <Link to={`/maintenance/${maintenanceTicketId}`} title={'Visualizar manutenção ativa'}><i className={'fas fa-eye mr-2'} /></Link>
                            : <Link to={'/maintenance'} title={'Nova Manutenção'}><i className={'fas fa-calendar-plus'} /></Link>}
                    </span>
                }
            })
        };
    }

    handleModal(action) {
        (action === 'maintenance' || action === 'prospect') ? this.setState({ showModal: action }) : this.setState({ showModal: null });
    }

    async handleNewPerson(e) {
        e.preventDefault();
        const options = this.state.options;
        const personData = this.state.newPersonData;
        const headers = { 'Content-Type': 'application/json' };
        const postData = {
            professional: personData.professional,
            name: (personData.firstName + ' ' + personData.lastName),
            prospectDate: new Date(`${personData.prospectDate}T00:00:00`).toISOString(),
            prospectSourceOption: this.getById(options.prospectSourceOptions, personData.prospectSourceOption),
            salesManUser: this.getById(options.systemUserOptions, personData.salesman)
        }
        if (!!personData.email) postData.email = personData.email;
        if (!!personData.telephone) postData.phoneList = [{ value: removeMask(personData.telephone) }];

        this.setState({ isFetching: true });

        try {
            await api.post(`/service/backoffice/person`, postData, { headers: headers })
                .then((response) => {
                    this.setState({
                        searchError: { type: 'success ', message: 'Usuário criado com sucesso' },
                        searchData: { name: postData.name },
                        showModal: false,
                        isFetching: false
                    });
                    this.handleSearch(e);
                });
        } catch (error) {
            if (error.response.status === 400) {
                console.log(error);
                this.setState({
                    fieldError: {
                        field: error.response.data.field,
                        message: error.response.data.message
                    },
                    isFetching: false,
                });
            } else {
                console.log(error);
                this.setState({
                    fieldError: {
                        field: 'other',
                        message: error.response.data.message
                    },
                    isFetching: false,
                });
            }
        }
    }

    async handleNewMaintenance(e) {
        e.preventDefault();
        const options = this.state.options;
        const personData = this.state.newPersonData;
        const headers = { 'Content-Type': 'application/json' };
        const postData = {
            personData: {
                professional: personData.professional,
                name: (personData.firstName + ' ' + personData.lastName),
                prospectDate: new Date(`${personData.prospectDate}T00:00:00`).toISOString(),
                prospectSourceOption: this.getById(options.prospectSourceOptions, personData.prospectSourceOption),
                salesManUser: this.getById(options.systemUserOptions, personData.salesman)
            }
        }
        if (!!personData.email) postData.personData.email = personData.email;
        if (!!personData.telephone) postData.personData.phoneList = [{ value: removeMask(personData.telephone) }];

        this.setState({ isFetching: true });

        try {
            await api.post(`/service/maintenances`, postData, { headers: headers })
                .then((response) => {
                    this.setState({
                        searchError: { type: 'success ', message: 'Usuário criado com sucesso' },
                        searchData: { name: postData.name },
                        showModal: false,
                        isFetching: false
                    });
                    this.props.history.push(`/maintenance/${response.data.id}`);
                });
        } catch (error) {
            if (error.response.status === 400) {
                console.log(error);
                this.setState({
                    fieldError: {
                        field: error.response.data.field,
                        message: error.response.data.message
                    },
                    isFetching: false,
                });
            } else {
                console.log(error);
                this.setState({
                    fieldError: {
                        field: 'other',
                        message: error.response.data.message
                    },
                    isFetching: false,
                });
            }
        }
    }

    getById(data, name) {
        const filteredData = data.filter((item) => {
            return item.name === name;
        });
        return filteredData[0];
    }

    render() {
        return (
            <PageContainer>
                <div className='container-fluid'>
                    <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                        <h1 className='h3 mb-0 text-gray-800'>Busca de Clientes</h1>
                    </div>
                    <SearchUser
                        handleSearch={(e) => this.handleSearch(e)}
                        handleFieldChange={(field, value) => this.handleFieldChange('searchData', field, value)}
                        searchError={this.state.searchError}
                        isFetching={this.state.isFetching} />

                    {(!!this.state.searchResult && <ResultTable
                        resultData={this.state.searchResult}
                        primaryActionButtonTitle='Nova Triagem'
                        secundaryActionButtonTitle='Nova Manutenção'
                        handleActionButton={(action) => this.handleModal(action)}
                        entries={20} />)}

                    {(!!this.state.options && <CreateUserModal
                        show={(this.state.showModal === null) ? false : true }
                        isFetching={this.state.isFetching}
                        options={this.state.options}
                        error={this.state.fieldError}
                        handleClose={(action) => this.handleModal(action)}
                        type={this.state.showModal}
                        handleFieldChange={(field, value) => this.handleFieldChange('newPersonData', field, value)}
                        handleSubmit={(e) => { (this.state.showModal === 'prospect') ? this.handleNewPerson(e) : this.handleNewMaintenance(e) }} />)}
                </div>
            </PageContainer >
        );
    }
}
